var __extensible_get__ = require("extensible-runtime").immutable;

import axios from 'axios';
export var addTask = function addTask(payload) {
  return axios.post('/tasks', payload);
};
export var getTask = function getTask(_ref) {
  var id = __extensible_get__(_ref, "id");

  return axios.get("/tasks/".concat(id));
};
export var getTaskList = function getTaskList(payload) {
  var users = payload.users.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, user) {
    return acc + "users=".concat(user, "&");
  }, '');
  var types = payload.types.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, type) {
    return acc + "types=".concat(type, "&");
  }, '');
  var statusTypes = payload.statusTypes.filter(function (val) {
    return val !== -1;
  }).reduce(function (acc, statusType) {
    return acc + "statusTypes=".concat(statusType, "&");
  }, '');
  var trailers = payload.trailers.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, trailer) {
    return acc + "trailers=".concat(trailer, "&");
  }, '');
  var shipments = payload.shipments.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, shipments) {
    return acc + "shipments=".concat(shipments, "&");
  }, '');
  var scacs = payload.scacs.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, scac) {
    return acc + "scacs=".concat(scac, "&");
  }, '');
  var locations = payload.locations.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, location) {
    return acc + "locations=".concat(location, "&");
  }, '');
  var dockgroups = '';
  if (payload.dockgroups && typeof payload.dockgroups != 'undefined') dockgroups = payload.dockgroups.filter(function (val) {
    return val !== 0;
  }).reduce(function (acc, dockgroup) {
    return acc + "dockgroups=".concat(dockgroup, "&");
  }, '');
  var startDate = payload.startDate ? "startDate=".concat(payload.startDate, "&") : '';
  var endDate = payload.endDate ? "endDate=".concat(payload.endDate, "&") : '';
  var query = users + types + statusTypes + trailers + shipments + scacs + locations + startDate + endDate + dockgroups;
  return axios.get("/tasks".concat(query.length ? '?' : '').concat(query.slice(0, query.length - 1)));
};
export var getTaskListSummary = function getTaskListSummary() {
  return axios.get('/tasks/summary');
};
export var updateTask = function updateTask(payload) {
  return axios.put("/tasks/".concat(payload.Task.ID), payload);
};
export var setPosition = function setPosition(payload) {
  return axios.put('/tasks/position', payload);
};
export var setStatus = function setStatus(payload) {
  return axios.put('/tasks/status', payload);
};
export var deleteTask = function deleteTask(id) {
  return axios["delete"]("/tasks/".concat(id));
};
export var exportReport = function exportReport(payload) {
  return axios.post('/tasks/reportexport', payload);
};
export var overrideTaskByDelete = function overrideTaskByDelete(id) {
  return axios["delete"]("/tasks/".concat(id, "?isOverrideTask=true"));
};
export var overrideTaskByStatus = function overrideTaskByStatus(payload) {
  return axios.put('/tasks/status', payload);
};