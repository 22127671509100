var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import { trailerSchemas } from '../schemas';
import { isMoment } from 'moment';
import { DATE_FORMAT } from '../config/constants';
import moment from 'moment';
var positionPayload = Immutable.fromJS({
  id: 0,
  newIndex: -1,
  oldIndex: -1
});
export var initFilters = Immutable.fromJS({
  users: [0],
  types: [0],
  shipments: [0],
  statusTypes: [0, 2],
  trailers: [0],
  scacs: [0],
  locations: [0],
  startDate: moment().subtract(1, 'days'),
  endDate: moment(),
  dockgroups: [0]
});
export var initialFilters = function initialFilters() {
  return {
    users: [0],
    types: [0],
    shipments: [0],
    statusTypes: [0, 2],
    trailers: [0],
    scacs: [0],
    locations: [0],
    startDate: moment().subtract(1, 'days'),
    endDate: moment(),
    dockgroups: [0]
  };
};
export var initPayload = function initPayload() {
  return {
    Action: 0,
    BoxTemperature: 0,
    FromLocationID: 0,
    FromLocationName: '',
    ID: 0,
    Message: '',
    Priority: 0,
    ScacID: 0,
    ScacName: '',
    SetTemperature: 0,
    ShipmentNumber: '',
    StartTime: '',
    StatusType: 0,
    SubStatusType: 0,
    TargetTemperature: '',
    ToLocationID: 0,
    ToLocationName: '',
    TrailerNumber: '',
    TrailerStatusType: 0,
    UserID: 0,
    Trailer: trailerSchemas.initPayload(),
    Intent: 0,
    Continuous: false,
    ElapsedPending: '',
    ElapsedPendingString: '',
    TrailerWeight: '',
    Inspection: false
  };
};
export var addTask = function addTask() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Immutable.fromJS(initPayload());
  return {
    Task: {
      FromLocationID: obj.get('FromLocationID'),
      // Location lookup
      ToLocationID: obj.get('ToLocationID'),
      // location lookup
      Action: obj.get('Action'),
      // action type
      Priority: obj.get('Priority') ? 1 : 0,
      Message: obj.get('Message'),
      Trailer: obj.get('Trailer'),
      Intent: obj.get('Intent'),
      Continuous: obj.get('Continuous')
    }
  };
};
export var setPosition = function setPosition() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : positionPayload;
  return {
    TaskID: obj.get('id'),
    OldPosition: obj.get('oldIndex'),
    TaskPosition: obj.get('newIndex'),
    Trailers: obj.get('trailers'),
    Scacs: obj.get('scacs'),
    Dockgroups: obj.get('dockgroups'),
    StatusTypes: obj.get('statusTypes'),
    Users: obj.get('users'),
    EndDate: obj.get('endDate'),
    StartDate: obj.get('startDate'),
    Types: obj.get('types'),
    Shipments: obj.get('shipments'),
    Locations: obj.get('locations'),
    Actions: obj.get('actions')
  };
};
export var setStatus = function setStatus() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : positionPayload;
  return {
    TaskID: obj.get('ID'),
    TaskStatusType: obj.get('StatusType'),
    TaskSubStatusType: obj.get('SubStatusType')
  };
};
export var updateTask = function updateTask() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Immutable.fromJS(initPayload());
  return {
    Task: {
      ID: obj.get('ID'),
      StatusType: obj.get('StatusType'),
      SubStatusType: obj.get('SubStatusType'),
      FromLocationID: obj.get('FromLocationID'),
      ToLocationID: obj.get('ToLocationID'),
      UserID: obj.get('UserID'),
      Action: obj.get('Action'),
      Priority: obj.get('Priority') ? 1 : 0,
      Trailer: obj.get('Trailer'),
      Continuous: obj.get('Continuous'),
      Intent: obj.get('Intent'),
      TrailerWeight: obj.get('TrailerWeight') !== '' ? parseInt(obj.get('TrailerWeight')) : 0
    }
  };
};
export var getTaskList = function getTaskList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters;
  return {
    users: obj.get('users').toJS(),
    types: obj.get('types').toJS(),
    statusTypes: obj.get('statusTypes').toJS(),
    // statusTypes: [3],
    trailers: obj.get('trailers').toJS(),
    scacs: obj.get('scacs').toJS(),
    shipments: obj.get('shipments').toJS(),
    locations: obj.get('locations').toJS(),
    startDate: isMoment(obj.get('startDate')) ? obj.get('startDate').format(DATE_FORMAT) : obj.get('startDate'),
    endDate: isMoment(obj.get('endDate')) ? obj.get('endDate').format(DATE_FORMAT) : obj.get('endDate'),
    dockgroups: obj.get('dockgroups') ? obj.get('dockgroups').toJS() : ''
  };
};
export var exportPayload = function exportPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Immutable.Map();
  return {
    StartDate: obj.get('StartDate'),
    EndDate: obj.get('EndDate')
  };
};