function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import moment from 'moment';
import { taskSchemas } from '../schemas';
export var types = {
  ADD_TASK: 'ADD_TASK',
  REQUEST_ADD_TASK: 'REQUEST_ADD_TASK',
  RECEIVE_ADD_TASK: 'RECEIVE_ADD_TASK',
  RECEIVE_ADD_TASK_FAIL: 'RECEIVE_ADD_TASK_FAIL',
  GET_TASK: 'GET_TASK',
  REQUEST_GET_TASK: 'REQUEST_GET_TASK',
  RECEIVE_GET_TASK: 'RECEIVE_GET_TASK',
  RECEIVE_GET_TASK_FAIL: 'RECEIVE_GET_TASK_FAIL',
  GET_TASK_LIST: 'GET_TASK_LIST',
  REQUEST_GET_TASK_LIST: 'REQUEST_GET_TASK_LIST',
  RECEIVE_GET_TASK_LIST: 'RECEIVE_GET_TASK_LIST',
  RECEIVE_GET_TASK_LIST_FAIL: 'RECEIVE_GET_TASK_LIST_FAIL',
  GET_TASK_LIST_SUMMARY: 'GET_TASK_LIST_SUMMARY',
  REQUEST_GET_TASK_LIST_SUMMARY: 'REQUEST_GET_TASK_LIST_SUMMARY',
  RECEIVE_GET_TASK_LIST_SUMMARY: 'RECEIVE_GET_TASK_LIST_SUMMARY',
  RECEIVE_GET_TASK_LIST_SUMMARY_FAIL: 'RECEIVE_GET_TASK_LIST_SUMMARY_FAIL',
  UPDATE_TASK: 'UPDATE_TASK',
  REQUEST_UPDATE_TASK: 'REQUEST_UPDATE_TASK',
  RECEIVE_UPDATE_TASK: 'RECEIVE_UPDATE_TASK',
  RECEIVE_UPDATE_TASK_FAIL: 'RECEIVE_UPDATE_TASK_FAIL',
  DELETE_TASK: 'DELETE_TASK',
  REQUEST_DELETE_TASK: 'REQUEST_DELETE_TASK',
  RECEIVE_DELETE_TASK: 'RECEIVE_DELETE_TASK',
  RECEIVE_DELETE_TASK_FAIL: 'RECEIVE_DELETE_TASK_FAIL',
  OVERRIDE_TASK_DELETE: 'OVERRIDE_TASK_DELETE',
  REQUEST_OVERRIDE_TASK_DELETE: 'REQUEST_OVERRIDE_TASK_DELETE',
  RECEIVE_OVERRIDE_TASK_DELETE: 'RECEIVE_OVERRIDE_TASK_DELETE',
  RECEIVE_OVERRIDE_TASK_DELETE_FAIL: 'RECEIVE_OVERRIDE_TASK_DELETE_FAIL',
  EXPORT_REPORT: 'EXPORT_REPORT',
  REQUEST_EXPORT_REPORT: 'REQUEST_EXPORT_REPORT',
  RECEIVE_EXPORT_REPORT: 'RECEIVE_EXPORT_REPORT',
  RECEIVE_EXPORT_REPORT_FAIL: 'RECEIVE_EXPORT_REPORT_FAIL',
  SET_POSITION: 'SET_POSITION',
  REQUEST_SET_POSITION: 'REQUEST_SET_POSITION',
  RECEIVE_SET_POSITION: 'RECEIVE_SET_POSITION',
  RECEIVE_SET_POSITION_FAIL: 'RECEIVE_SET_POSITION_FAIL',
  SET_TASK_STATUS: 'SET_TASK_STATUS',
  REQUEST_SET_TASK_STATUS: 'REQUEST_SET_TASK_STATUS',
  RECEIVE_SET_TASK_STATUS: 'RECEIVE_SET_TASK_STATUS',
  RECEIVE_SET_TASK_STATUS_FAIL: 'RECEIVE_SET_TASK_STATUS_FAIL',
  OVERRIDE_TASK_STATUS: 'OVERRIDE_TASK_STATUS',
  REQUEST_OVERRIDE_TASK_STATUS: 'REQUEST_OVERRIDE_TASK_STATUS',
  RECEIVE_OVERRIDE_TASK_STATUS: 'RECEIVE_OVERRIDE_TASK_STATUS',
  RECEIVE_OVERRIDE_TASK_STATUS_FAIL: 'RECEIVE_OVERRIDE_TASK_STATUS_FAIL',
  UPDATE_TASK_FILTERS: 'UPDATE_TASK_FILTERS'
};
export var actions = {
  addTask: function addTask(payload, filters) {
    var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
    return {
      type: types.ADD_TASK,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestAddTask: function requestAddTask() {
    return {
      type: types.REQUEST_ADD_TASK
    };
  },
  receiveAddTask: function receiveAddTask(task) {
    return {
      type: types.RECEIVE_ADD_TASK,
      task: task
    };
  },
  receiveAddTaskFail: function receiveAddTaskFail() {
    return {
      type: types.RECEIVE_ADD_TASK_FAIL
    };
  },
  getTask: function getTask(id) {
    return {
      type: types.GET_TASK,
      id: id
    };
  },
  requestGetTask: function requestGetTask() {
    return {
      type: types.REQUEST_GET_TASK
    };
  },
  receiveGetTask: function receiveGetTask(task) {
    return {
      type: types.RECEIVE_GET_TASK,
      task: task
    };
  },
  receiveGetTaskFail: function receiveGetTaskFail() {
    return {
      type: types.RECEIVE_GET_TASK_FAIL
    };
  },
  getTaskList: function getTaskList(payload) {
    return {
      type: types.GET_TASK_LIST,
      payload: payload
    };
  },
  requestGetTaskList: function requestGetTaskList() {
    return {
      type: types.REQUEST_GET_TASK_LIST
    };
  },
  receiveGetTaskList: function receiveGetTaskList(tasks) {
    return {
      type: types.RECEIVE_GET_TASK_LIST,
      tasks: tasks
    };
  },
  receiveGetTaskListFail: function receiveGetTaskListFail() {
    return {
      type: types.RECEIVE_GET_TASK_LIST_FAIL
    };
  },
  getTaskListSummary: function getTaskListSummary() {
    return {
      type: types.GET_TASK_LIST_SUMMARY
    };
  },
  requestGetTaskListSummary: function requestGetTaskListSummary() {
    return {
      type: types.REQUEST_GET_TASK_LIST_SUMMARY
    };
  },
  receiveGetTaskListSummary: function receiveGetTaskListSummary(summary) {
    return {
      type: types.RECEIVE_GET_TASK_LIST_SUMMARY,
      summary: summary
    };
  },
  receiveGetTaskListSummaryFail: function receiveGetTaskListSummaryFail() {
    return {
      type: types.RECEIVE_GET_TASK_LIST_SUMMARY_FAIL
    };
  },
  updateTask: function updateTask(payload, filters, callback) {
    return {
      type: types.UPDATE_TASK,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestUpdateTask: function requestUpdateTask() {
    return {
      type: types.REQUEST_UPDATE_TASK
    };
  },
  receiveUpdateTask: function receiveUpdateTask(task) {
    return {
      type: types.RECEIVE_UPDATE_TASK,
      task: task
    };
  },
  receiveUpdateTaskFail: function receiveUpdateTaskFail() {
    return {
      type: types.RECEIVE_UPDATE_TASK_FAIL
    };
  },
  deleteTask: function deleteTask(id, callback) {
    return {
      type: types.DELETE_TASK,
      id: id,
      callback: callback
    };
  },
  requestDeleteTask: function requestDeleteTask() {
    return {
      type: types.REQUEST_DELETE_TASK
    };
  },
  receiveDeleteTask: function receiveDeleteTask(id) {
    return {
      type: types.RECEIVE_DELETE_TASK,
      id: id
    };
  },
  receiveDeleteTaskFail: function receiveDeleteTaskFail() {
    return {
      type: types.RECEIVE_DELETE_TASK_FAIL
    };
  },

  /**methods to be called when any task override button is clicked */
  overrideTaskDelete: function overrideTaskDelete(id, callback) {
    return {
      type: types.OVERRIDE_TASK_DELETE,
      id: id,
      callback: callback
    };
  },
  requestOverrideTaskDelete: function requestOverrideTaskDelete() {
    return {
      type: types.REQUEST_OVERRIDE_TASK_DELETE
    };
  },
  receiveOverrideTaskDelete: function receiveOverrideTaskDelete(id) {
    return {
      type: types.RECEIVE_OVERRIDE_TASK_DELETE,
      id: id
    };
  },
  receiveOverrideTaskDeleteFail: function receiveOverrideTaskDeleteFail() {
    return {
      type: types.RECEIVE_OVERRIDE_TASK_DELETE_FAIL
    };
  },
  exportReport: function exportReport(payload, callback) {
    return {
      type: types.EXPORT_REPORT,
      payload: payload,
      callback: callback
    };
  },
  requestExportReport: function requestExportReport() {
    return {
      type: types.REQUEST_EXPORT_REPORT
    };
  },
  receiveExportReport: function receiveExportReport() {
    return {
      type: types.RECEIVE_EXPORT_REPORT
    };
  },
  receiveExportReportFail: function receiveExportReportFail() {
    return {
      type: types.RECEIVE_EXPORT_REPORT_FAIL
    };
  },
  setPosition: function setPosition(payload, filters) {
    return {
      type: types.SET_POSITION,
      payload: payload,
      filters: filters
    };
  },
  requestSetPosition: function requestSetPosition() {
    return {
      type: types.REQUEST_SET_POSITION
    };
  },
  receiveSetPosition: function receiveSetPosition(_ref) {
    var id = __extensible_get__(_ref, "id");

    var oldIndex = __extensible_get__(_ref, "oldIndex");

    var newIndex = __extensible_get__(_ref, "newIndex");

    return {
      type: types.RECEIVE_SET_POSITION,
      id: id,
      oldIndex: oldIndex,
      newIndex: newIndex
    };
  },
  receiveSetPositionFail: function receiveSetPositionFail() {
    return {
      type: types.RECEIVE_SET_POSITION_FAIL
    };
  },
  setTaskStatus: function setTaskStatus(payload, filters, callback) {
    return {
      type: types.SET_TASK_STATUS,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestSetTaskStatus: function requestSetTaskStatus() {
    return {
      type: types.REQUEST_SET_TASK_STATUS
    };
  },
  receiveSetTaskStatus: function receiveSetTaskStatus(task) {
    return {
      type: types.RECEIVE_SET_TASK_STATUS,
      task: task
    };
  },
  receiveSetTaskStatusFail: function receiveSetTaskStatusFail() {
    return {
      type: types.RECEIVE_SET_TASK_STATUS_FAIL
    };
  },

  /**method called when override pause/override complete is clicked */
  overrideTaskStatus: function overrideTaskStatus(payload, filters, callback) {
    return {
      type: types.OVERRIDE_TASK_STATUS,
      payload: payload,
      filters: filters,
      callback: callback
    };
  },
  requestOverrideTaskStatus: function requestOverrideTaskStatus() {
    return {
      type: types.REQUEST_OVERRIDE_TASK_STATUS
    };
  },
  receiveOverrideTaskStatus: function receiveOverrideTaskStatus(task) {
    return {
      type: types.RECEIVE_OVERRIDE_TASK_STATUS,
      task: task
    };
  },
  receiveOverrideTaskStatusFail: function receiveOverrideTaskStatusFail() {
    return {
      type: types.RECEIVE_OVERRIDE_TASK_STATUS_FAIL
    };
  },
  updateTaskFilters: function updateTaskFilters(filters) {
    return {
      type: types.UPDATE_TASK_FILTERS,
      filters: filters
    };
  }
};

var receiveDeleteTask = function receiveDeleteTask(state, _ref2) {
  var id = __extensible_get__(_ref2, "id");

  var index = state.get('tasks').findIndex(function (task) {
    return task.get('ID') === id;
  });
  return state.update('tasks', function (tasks) {
    return tasks["delete"](index);
  });
};

var receiveUpdateTask = function receiveUpdateTask(state, action) {
  var index = state.get('tasks').findIndex(function (task) {
    return task.get('ID') === action.task.ID;
  });
  return state.setIn(['tasks', index], Immutable.fromJS(action.task));
};

var receiveAddTask = function receiveAddTask(state, action) {
  return state.update('tasks', function (tasks) {
    return tasks.push(Immutable.fromJS(action.task));
  });
};

var receiveSetPosition = function receiveSetPosition(state, action) {
  var oldIndex = __extensible_get__(action, "oldIndex");

  var newIndex = __extensible_get__(action, "newIndex");

  var tasks = state.get('tasks').toJS();

  var _tasks$splice = tasks.splice(oldIndex, 1);

  var _tasks$splice2 = _slicedToArray(_tasks$splice, 1);

  var movedTask = _tasks$splice2[0];
  tasks.splice(newIndex, 0, movedTask);
  tasks = tasks.map(function (task, index) {
    return _objectSpread(_objectSpread({}, task), {}, {
      Position: index,
      Resequenced: task.ID === movedTask.ID ? 1 : task.Resequenced
    });
  });
  var immutableTasks = Immutable.fromJS(tasks);
  return state.set('tasks', immutableTasks);
};

var initialState = Immutable.fromJS({
  tasks: [],
  currentTask: taskSchemas.initPayload(),
  summary: {},
  filters: taskSchemas.initialFilters(),
  listLoader: false,
  buttonLoader: false,
  dialogLoader: false,
  itemLoader: false
});

var task = function task() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.REQUEST_GET_TASK:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_TASK:
      return state.set('currentTask', action.task).set('listLoader', false);

    case types.RECEIVE_GET_TASK_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_TASK_LIST:
      return state.set('listLoader', true);

    case types.RECEIVE_GET_TASK_LIST:
      return state.set('tasks', Immutable.fromJS(action.tasks)).set('listLoader', false);

    case types.RECEIVE_GET_TASK_LIST_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_GET_TASK_LIST_SUMMARY:
      return state;

    case types.RECEIVE_GET_TASK_LIST_SUMMARY:
      return state.set('summary', Immutable.fromJS(action.summary));

    case types.RECEIVE_GET_TASK_LIST_SUMMARY_FAIL:
      return state;

    case types.REQUEST_ADD_TASK:
      return state.set('buttonLoader', true);

    case types.RECEIVE_ADD_TASK:
      return receiveAddTask(state, action).set('buttonLoader', false);

    case types.RECEIVE_ADD_TASK_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_UPDATE_TASK:
      return state.set('dialogLoader', true);

    case types.RECEIVE_UPDATE_TASK:
      return receiveUpdateTask(state, action).set('dialogLoader', false);

    case types.RECEIVE_UPDATE_TASK_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_DELETE_TASK:
      return state.set('dialogLoader', true);

    case types.RECEIVE_DELETE_TASK:
      return receiveDeleteTask(state, action).set('dialogLoader', false);

    case types.RECEIVE_DELETE_TASK_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_OVERRIDE_TASK_DELETE:
      return state.set('buttonLoader', true);

    case types.RECEIVE_OVERRIDE_TASK_DELETE:
      return receiveDeleteTask(state, action).set('buttonLoader', false);

    case types.RECEIVE_OVERRIDE_TASK_DELETE_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_EXPORT_REPORT:
      return state.set('buttonLoader', true);

    case types.RECEIVE_EXPORT_REPORT:
      return state.set('buttonLoader', false);

    case types.RECEIVE_EXPORT_REPORT_FAIL:
      return state.set('buttonLoader', false);

    case types.REQUEST_SET_POSITION:
      return state.set('listLoader', true);

    case types.RECEIVE_SET_POSITION:
      return receiveSetPosition(state, action).set('listLoader', false);

    case types.RECEIVE_SET_POSITION_FAIL:
      return state.set('listLoader', false);

    case types.REQUEST_SET_TASK_STATUS:
      return state.set('dialogLoader', true);

    case types.RECEIVE_SET_TASK_STATUS:
      return receiveUpdateTask(state, action).set('dialogLoader', false);

    case types.RECEIVE_SET_TASK_STATUS_FAIL:
      return state.set('dialogLoader', false);

    case types.REQUEST_OVERRIDE_TASK_STATUS:
      return state.set('buttonLoader', true);

    case types.RECEIVE_OVERRIDE_TASK_STATUS:
      return receiveUpdateTask(state, action).set('buttonLoader', false);

    case types.RECEIVE_OVERRIDE_TASK_STATUS_FAIL:
      return state.set('buttonLoader', false);

    case types.UPDATE_TASK_FILTERS:
      return state.set('filters', action.filters);

    default:
      return state;
  }
};

export var selectors = {
  getTaskList: function getTaskList(state) {
    return state.task.get('tasks');
  },
  getTaskListSummary: function getTaskListSummary(state) {
    return state.task.get('summary');
  },
  getTaskById: function getTaskById(state, id) {
    return state.task.get('tasks').find(function (task) {
      return task.get('ID') === id;
    });
  },
  getCurrentTask: function getCurrentTask(state) {
    return state.task.get('currentTask');
  },
  getTaskFilters: function getTaskFilters(state) {
    return state.task.get('filters');
  },
  getListLoader: function getListLoader(state) {
    return state.task.get('listLoader');
  },
  getItemLoader: function getItemLoader(state) {
    return state.task.get('itemLoader');
  },
  getButtonLoader: function getButtonLoader(state) {
    return state.task.get('buttonLoader');
  },
  getDialogLoader: function getDialogLoader(state) {
    return state.task.get('dialogLoader');
  }
};
export default task;